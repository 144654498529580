import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import {
  makeStyles,
  styled,
} from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import { spacing } from '@material-ui/system'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import globalStyles from 'src/styles/globalStyles'

import {
  Container as MuiContainer,
  Hidden,
  Typography,
  Grid as MuiGrid,
  Box,
  AppBar,
  Toolbar,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ButtonGroup,
  IconButton,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button as MuiButton
} from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import PrintIcon from '@material-ui/icons/Print'
import DescriptionIcon from '@material-ui/icons/Description'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import WorkIcon from '@material-ui/icons/Work'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions'
import AssignmentIcon from '@material-ui/icons/Assignment'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import SpeedIcon from '@material-ui/icons/Speed'
import HelpIcon from '@material-ui/icons/Help'
import BeachAccessIcon from '@material-ui/icons/BeachAccess'

import Layout from 'src/components/layout'
import Application from 'src/components/jobOffers/application'
import { SectionHeader } from 'src/components/headers'

import 'swiper/swiper.scss'
import 'src/styles/swiper.scss'
import SwiperCore, {
  Autoplay as SwiperAutoplay,
  Navigation as SwiperNavigation,
  Pagination as SwiperPagination
} from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import { parseAlt } from 'src/helpers'

const Container = styled(MuiContainer)(spacing)
const Grid = styled(MuiGrid)(spacing)
const Button = styled(MuiButton)(spacing)

SwiperCore.use([SwiperAutoplay, SwiperNavigation, SwiperPagination])

const useStyles = makeStyles(theme => ({
  ...globalStyles,
  bannerSlider: {
    zIndex: '100',
    width: '100%',
    backgroundColor: '#606060',
  },
  bannerSliderImg: {
    position: 'absolute',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    opacity: '0.4',
  },
  bannerHeader: {
    height: '100%',
    zIndex: '1000',
    position: 'relative',
    color: 'white',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-end',
    textAlign: 'center'
  },
  bannerHeading: {
    fontSize: '3rem'
  },
  bannerHeadline: {
    display: 'inline-block'
  },
  bannerSubheader: {
    fontSize: '20px'
  },
  paragraphWrapper: {
    '& p': {
      marginTop: '16px',
      marginBottom: '16px'
    }
  },
  benefitsWrapper: {
    width: '80%'
  },
  benefit: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  benefitIconWrapper: {
    width: '140px',
    height: '140px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: '#a0c4ff',
  },
  benefitIconWrapperGreen: {
    backgroundColor: '#a2dda7',
  },
  benefitIcon: {
    color: '#3871c2',
    fontSize: '6rem'
  },
  benefitIconGreen: {
    color: '#27a520',
  },
  benefitHeader: {
    fontSize: '1.1rem'
  },
  trucksSlider: {
    width: '100%',
    borderRadius: '4px',
  },
  offer: {
    border: 'solid 1px rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    borderRadius: '4px',
    overflow: 'hidden',
    '& p': {
      marginBottom: '0px'
    }
  },
  offer: {
    boxShadow: 'none',
    '& .MuiAccordionSummary-root': {
      paddingLeft: '0px'
    }
  },
  offerDetails: {
    padding: '0px'
  },
  offerHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0)
  },
  [theme.breakpoints.up('xs')]: {
    bannerSlider: {
      height: '400px',
    }
  },
  [theme.breakpoints.down('sm')]: {
    bannerHeading: {
      fontSize: '2.2rem'
    },
  },
  [theme.breakpoints.up('md')]: {
    bannerHeader: {
      textAlign: 'left'
    },
  },
  [theme.breakpoints.up('lg')]: {
    bannerSlider: {
      height: '600px'
    }
  },
  slide: {
    overflow: 'hidden'
  },
}))

const icons = {
  work: WorkIcon,
  localShipping: LocalShippingIcon,
  trendingUp: TrendingUpIcon,
  emojiEmotions: EmojiEmotionsIcon,
  assignment: AssignmentIcon,
  verifiedUser: VerifiedUserIcon,
  localHospital: LocalHospitalIcon,
  speed: SpeedIcon,
  help: HelpIcon,
  beachAccess: BeachAccessIcon,
}

const JobOffers = ({ data, pageContext }) => {
  const JobOffers = data.contentfulJobOffers
  const classes = useStyles()
  const theme = useTheme()
  const bptMd = useMediaQuery(theme.breakpoints.up('md'))
  const bptSm = useMediaQuery(theme.breakpoints.up('sm'))

  const [openedOffer, setOpenedOffer] = useState(false)
  const handleOfferOpen = (id) => (event, newExpanded) => {
    setOpenedOffer(newExpanded ? id : false)
  }

  return (
    <Layout pageContext={pageContext}>
      <Swiper className={classes.bannerSlider} pagination={{ clickable: true }} speed={1000} autoplay={{ delay: 4000, disableOnInteraction: true }}>
        {JobOffers.bannerSlider.map(slide => (
          <SwiperSlide className={classes.slide} key={slide.id}>
            <GatsbyImage className={classes.bannerSliderImg} image={getImage(slide.picture)} alt={parseAlt(slide.picture, pageContext)} />
            <Container className={classes.bannerHeader} pb={12}>
              <div>
                <span className={`${classes.bannerHeading} ${classes.boldText} ${classes.headerLine}`}>{slide.title}</span>
              </div>
              <p className={`${classes.boldText} ${classes.bannerSubheader}`}>{slide.caption}</p>
              <AnchorLink className={classes.navLink} to={'/strefa-kierowcy#oferty-pracy'}>
                <Button variant="contained" disableElevation mt={1} color="primary">{JobOffers.welcomeCta}</Button>
              </AnchorLink>
            </Container>
          </SwiperSlide>
        ))}
      </Swiper>

      {/*}<Box pt={10} pb={10} className={classes.tint}>
        <Container>
          <Box textAlign="center">
            <Typography className={`${classes.boldText}`} variant="h5" component="div">
              {JobOffers.welcomeHeader}
            </Typography>
            <Box pt={2} pb={4}>{JobOffers.welcomeParagraph}</Box>
            <AnchorLink className={classes.navLink} to={'/strefa-kierowcy#oferty-pracy'}>
              <Button variant="contained" disableElevation color="primary">{JobOffers.welcomeCta}</Button>
            </AnchorLink>
          </Box>
        </Container>
      </Box>*/}

      <Container pt={8} className={classes.paragraphWrapper}>
        <SectionHeader>{JobOffers.advantages}</SectionHeader>
        <p className={`${classes.highlight} ${classes.boldText}`}>{JobOffers.welcomeHeader}</p>
        <Box>{JobOffers.welcomeParagraph}</Box>
        <p className={`${classes.highlight} ${classes.boldText}`}>{JobOffers.advantagesSubheader1}</p>
        <Grid container spacing={7} pt={bptSm ? 6 : 4}>
          {JobOffers.advantagesEntries.map(advantage => {
            const AdvIcon = icons[advantage.icon] || false
            return (
              <Grid item xs={12} sm={6} md={4} mb={bptSm ? 2 : 0} className={classes.benefit} key={advantage.id}>
                <Box className={classes.benefitIconWrapper}>
                  <AdvIcon className={classes.benefitIcon} />
                </Box>
                <Box className={`${classes.boldText} ${classes.benefitHeader}`} pt={3}>{advantage.title}</Box>
                <Box pt={1}>{advantage.paragraph1}</Box>
              </Grid>
            )
          })}
        </Grid>
      </Container>

      <Container pt={bptSm ? 8 : 10} pb={9}>
        <SectionHeader>{JobOffers.benefits}</SectionHeader>
        <Box display="flex" justifyContent="center" pt={bptSm ? 8 : 6}>
          <Grid container className={bptMd ? classes.benefitsWrapper : ''} spacing={7}>
            {JobOffers.benefitsEntries.map(benefit => {
              const BenIcon = icons[benefit.icon] || false
              return (
                <Grid item xs={12} sm={6} mb={bptSm ? 2 : 0} className={classes.benefit} key={benefit.id}>
                  <Box className={`${classes.benefitIconWrapper} ${classes.benefitIconWrapperGreen}`}>
                    <BenIcon className={`${classes.benefitIcon} ${classes.benefitIconGreen}`} />
                  </Box>
                  <Box className={`${classes.boldText} ${classes.benefitHeader}`} pt={3}>{benefit.title}</Box>
                  <Box pt={1}>{benefit.paragraph1}</Box>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Container>

      <Box className={classes.tint} py={6}>
        <Container>
          <Grid container spacing={bptMd ? 6 : 1}>
            <Grid item xs={12} md={6}>
              <SectionHeader>{JobOffers.fleet}</SectionHeader>
              <p className={`${classes.highlight} ${classes.boldText}`}>{JobOffers.fleetSubheader1}</p>
              <AnchorLink className={classes.navLink} to={'/strefa-kierowcy#wyslij-zgloszenie'}>
                <Button variant="contained" disableElevation color="primary" mt={bptMd ? 2 : 0} mb={bptMd ? 0 : 2}>{JobOffers.fleetCta}</Button>
              </AnchorLink>
            </Grid>
            <Grid item xs={12} md={6}>
              <Swiper className={classes.trucksSlider} navigation pagination={{ clickable: true }} loop>
                {JobOffers.fleetPictures.map(picture => (
                  <SwiperSlide key={picture.id}>
                    <GatsbyImage image={getImage(picture)} alt={parseAlt(picture, pageContext)} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/*}<Box pt={10} pb={10} className={classes.tint}>
        <Container>
          <Box textAlign="center">
            <Typography className={`${classes.boldText}`} variant="h5" component="div">
              To tylko mała część tego co Cię czeka, pracując razem z nami!
            </Typography>
            <Box pt={2}>Jesteś gotowy? Sprawdź, czy szukamy właśnie Ciebie!</Box>
          </Box>
        </Container>
      </Box>*/}

      <Container pt={8} pb={8} id="oferty-pracy" className={classes.paragraphWrapper}>
        <SectionHeader>Oferty pracy</SectionHeader>
        <p className={`${classes.highlight} ${classes.boldText}`}>To tylko mała część tego co Cię czeka, pracując razem z nami!</p>
        <Box>Jesteś gotowy? Sprawdź, czy szukamy właśnie Ciebie!</Box>
        <Box pt={1}>
          {JobOffers.offersEntries.map(offer => (
            <Accordion className={classes.offer} expanded={openedOffer === offer.id} onChange={handleOfferOpen(offer.id)} key={offer.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <SectionHeader className={classes.offerHeader} h3>{offer.title}</SectionHeader>
              </AccordionSummary>
              <AccordionDetails className={classes.offerDetails}>
                <Box>
                  <GatsbyImage className={classes.rounded} image={getImage(offer.banner)} alt={parseAlt(offer.banner, pageContext)} />
                  <Grid container pt={3} spacing={4}>
                    <Grid item xs={12} md={4}>
                      <SectionHeader h4>{offer.sectionHeader1}</SectionHeader>
                      <Box className={classes.listWrapper}>
                        {offer.sectionBody1 ? (
                          documentToReactComponents(JSON.parse(offer.sectionBody1.raw))
                        ) : ''}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <SectionHeader h4>{offer.sectionHeader2}</SectionHeader>
                      <Box className={classes.listWrapper}>
                        {offer.sectionBody2 ? (
                          documentToReactComponents(JSON.parse(offer.sectionBody2.raw))
                        ) : ''}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <SectionHeader h4>{offer.sectionHeader3}</SectionHeader>
                      <Box className={classes.listWrapper}>
                        {offer.sectionBody3 ? (
                          documentToReactComponents(JSON.parse(offer.sectionBody3.raw))
                        ) : ''}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>

      {/*.MuiOutlinedInput-notchedOutline 3871c2*/}
      <Box style={{ backgroundColor: '#edf7ff' }}>
        <Container py={8} id="wyslij-zgloszenie">
          <SectionHeader>{JobOffers.application}</SectionHeader>
          <Application pageContext={pageContext} application={JobOffers.applicationForm} />
        </Container>
      </Box>
    </Layout>
  )
}

export default JobOffers

export const pageQuery = graphql`
  query JobOffersQuery($id: String!) {
    contentfulJobOffers(id: { eq: $id }) {
      name
      bannerSlider {
        id
        title
        caption
        picture {
          description
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      welcomeHeader
      welcomeParagraph
      welcomeCta
      advantages
      advantagesSubheader1
      advantagesEntries {
        id
        title
        paragraph1
        icon
      }
      benefits
      benefitsEntries {
        id
        title
        paragraph1
        icon
      }
      fleet
      fleetSubheader1
      fleetPictures {
        id
        description
        gatsbyImageData(layout: CONSTRAINED)
      }
      fleetCta
      offersSubheader
      offersEntries {
        id
        title
        banner {
          description
          gatsbyImageData(layout: CONSTRAINED)
        }
        sectionHeader1
        sectionBody1 {
          raw
        }
        sectionHeader2
        sectionBody2 {
          raw
        }
        sectionHeader3
        sectionBody3 {
          raw
        }
      }
      application
      applicationForm {
        title
        form {
          email
          invalidEmail
          phone
          invalidPhone
          requiredField
          minChars
          maxChars
          send
        }
        name
        uploadCv
        uploadCvNoFile
        comment
        error
        success
        send
      }
    }
  }
`

import React, { useState, useRef } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'

import {
  makeStyles,
  styled,
} from '@material-ui/core/styles'
import { spacing } from '@material-ui/system'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import globalStyles from 'src/styles/globalStyles'

import {
  Button as MuiButton,
  Container as MuiContainer,
  Hidden,
  Typography as MuiTypography,
  Grid as MuiGrid,
  Box,
  AppBar,
  Toolbar,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ButtonGroup,
  IconButton,
  TextField as MuiTextField,
  FormControl as MuiFormControl,
  InputLabel,
  Select,
  Tabs,
  Tab,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  FormGroup,
  Checkbox,
  Input,
  CircularProgress
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import SendIcon from '@material-ui/icons/Send'
import { SectionHeader } from 'src/components/headers'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

const Button = styled(MuiButton)(spacing)
const TextField = styled(MuiTextField)(spacing)
const FormControl = styled(MuiFormControl)(spacing)
const Typography = styled(MuiTypography)(spacing)
const Container = styled(MuiContainer)(spacing)
const Grid = styled(MuiGrid)(spacing)

const useStyles = makeStyles({
  ...globalStyles,
  banner: {
    overflow: 'hidden',
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    backgroundColor: '#606060',
    zIndex: '100',
    '& div': {
      opacity: '0.6',
      filter: 'blur(2px)'
    }
  },
  bannerHeader: {
    height: '500px',
    zIndex: '1000',
    position: 'relative',
    color: 'white',
  },
  boldText: {
    fontWeight: 800,
  },
  map: {
    overflow: 'hidden'
  },
  header: {
    fontSize: '1.5rem'
  },
  headerLine: {
    paddingTop: '5px',
    '&::before': {
      content: '""',
      display: 'block',
      width: '50px',
      height: '3px',
      position: 'relative',
      top: '-5px',
      backgroundColor: '#3871c2'
    }
  },
  fullWidth: {
    width: '100%'
  },
  noHeight: {
    height: '0%'
  },
  flexGrow: {
    flexGrow: '1'
  },
  smallSelect: {
    '& label': {
      transform: 'translate(14px, 12px) scale(1)'
    },
    '& .MuiSelect-root': {
      padding: '10.5px 14px'
    }
  },
  requirements: {
    minWidth: '300px',
    border: 'none',
    paddingLeft: '0px'
  },
  comment: {
    width: '100%',
    height: '100%'
  },
  inputFile: {
    display: 'none'
  },
  loading: {
    color: 'rgba(0, 0, 0, 0.26)'
  },
  showNumber: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
})

const Application = ({ pageContext, application }) => {
  const Application = application
  const Form = Application.form
  const classes = useStyles()
  const theme = useTheme()
  const bptMd = useMediaQuery(theme.breakpoints.up('md'))
  const bptLg = useMediaQuery(theme.breakpoints.up('lg'))

  const [showNumber, setShowNumber] = useState(false)
  const [attachedFile, setAttachedFile] = useState(Application.uploadCvNoFile)
  const [submitError, setSubmitError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [recaptchaResponse, setRecaptchaResponse] = useState(null)
  const inputFile = useRef(null)

  const msgs = {
    isRequired: Form.requiredField
  }
  const parseCharsError = (nr, msg) => msg.replace('{number}', nr)

  const Schema = Yup.object().shape({
    name: Yup.string().min(3, parseCharsError(3, Form.minChars)).max(50, parseCharsError(50, Form.maxChars)).required(msgs.isRequired),
    email: Yup.string().email(Form.invalidEmail).required(msgs.isRequired),
    phone: Yup.string().min(9, Form.invalidPhone).max(20, Form.invalidPhone).required(msgs.isRequired),
    comment: Yup.string().min(3, parseCharsError(3, Form.minChars)).max(250, parseCharsError(250, Form.maxChars)),
  })

  const handleShowNumber = () => {
    setShowNumber(true)
  }

  const handleInputFile = ev => {
    if (ev.target.files.length) setAttachedFile(ev.target.files[0].name)
    else setAttachedFile(Application.uploadCvNoFile)
  }

  const handleRecaptchaChange = (v) => setRecaptchaResponse(v)

  const afterSubmit = () => {
    setLoading(false)
    setTimeout(() => {
      setSubmitError(null)
    }, 4000)
  }
  const submitForm = (values, { resetForm }) => {
    setLoading(true)
    const data = new FormData()
    for (const key in values) { data.append(key, values[key]) }
    //data.append('position', position)
    data.append('recaptcha', recaptchaResponse || '')
    if (inputFile.current.files.length) data.append('attachment', inputFile.current.files[0])

    axios.post('https://api.fpdrozd.com/bama/job-application', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(res => {
      inputFile.current.value = null
      setSubmitError(false)
      resetForm()
      afterSubmit()
    })
    .catch(err => {
      setSubmitError(true)
      afterSubmit()
    })
  }

  return (
    <Box>
      <p>Na adres <a className={classes.link} href="mailto: rekrutacja@bamalogistics.pl">rekrutacja@bamalogistics.pl</a>, zadzwoń {showNumber ? (<a className={classes.link} href="tel:+48570551556">570 551 556</a>) : (<span id="application-show-number" onClick={handleShowNumber} className={`${classes.showNumber} ${classes.link}`}>(pokaż numer)</span>)} lub wypełnij formularz:</p>
      <Formik
        validationSchema={Schema}
        onSubmit={submitForm}
        initialValues={{
          name: '',
          email: '',
          phone: '',
          comment: ''
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
        }) => (
          <Box pt={2}>
            <TextField className={classes.fullWidth} value={values.name} onChange={handleChange} onBlur={handleBlur} error={touched.name && !!errors.name} helperText={touched.name && errors.name} name="name" label={Application.name} variant="outlined" size="small" />

            <Grid container spacing={bptMd ? 1 : 2} pt={2}>
              <Grid item xs={12} md={6}>
                <TextField className={classes.fullWidth} value={values.email} onChange={handleChange} onBlur={handleBlur} error={touched.email && !!errors.email} helperText={touched.email && errors.email} mr={1} name="email" label={Form.email} variant="outlined" size="small" />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField className={classes.fullWidth} value={values.phone} onChange={handleChange} onBlur={handleBlur} error={touched.phone && !!errors.phone} helperText={touched.phone && errors.phone} name="phone" label={Form.phone} variant="outlined" size="small" />
              </Grid>
            </Grid>

            <Box pt={2} pb={3}>
              <input
                className={classes.inputFile}
                ref={inputFile}
                onChange={handleInputFile}
                type="file"
                accept=".pdf, .docx"
              />
              <Button variant="outlined" color="primary" startIcon={<CloudUploadIcon />} mr={2} onClick={() => inputFile.current.click()}>
                {Application.uploadCv}
              </Button>
              <span>{attachedFile}</span>
            </Box>

            <TextField
              className={classes.comment}
              value={values.comment}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.comment && !!errors.comment}
              helperText={touched.comment && errors.comment}
              name="comment"
              label={Application.comment}
              multiline
              rows={8}
              variant="outlined"
              size="small"
            />

            {submitError === null ? '' : (
              <Box pt={2}>
                <Alert severity={submitError ? 'error' : 'success'}>
                  { submitError ? Application.error : Application.success }
                </Alert>
              </Box>
            )}
            <Box display="flex" justifyContent={bptMd ? 'space-between' : 'center'} flexDirection={bptMd ? 'row' : 'column'} alignItems={bptMd ? 'flex-end' : 'center'} flexWrap="wrap" pt={2}>
              <ReCAPTCHA onChange={handleRecaptchaChange} hl={pageContext.lang} sitekey="6Ldfv1YcAAAAAEBSPSM_Du8ZOB8rRjnt2oVojso6" />
              <Button onClick={handleSubmit} variant="contained" disabled={!!(loading || recaptchaResponse === null || Object.keys(errors).length)} disableElevation color="primary" mt={bptMd ? 0 : 3} endIcon={loading ? <CircularProgress className={classes.loading} size={20} /> : <SendIcon />}>
                {Application.send}
              </Button>
            </Box>
          </Box>
        )}
      </Formik>
    </Box>
  )
}

export default Application
